/* Pre-set Styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  text-decoration: none;
}

/* CSS Reset */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Font Imports */
/* Montserrat */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
/* Inter */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
/* Krona One */
@import url("https://fonts.googleapis.com/css2?family=Krona+One&display=swap");
/* Space Grotesk */
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
/* Black Ops One */
@import url("https://fonts.googleapis.com/css2?family=Black+Ops+One&display=swap");
/* Bungee */
@import url("https://fonts.googleapis.com/css2?family=Bungee&display=swap");
/* Bebas Neue */
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

/* Global */
html body {
  background-color: black;
}

@font-face {
  font-family: "BhostraLalaBold";
  src: local("BhostraLalaBold"),
    url(./assets/fonts/BhostraLalaBold.otf) format("opentype");
}

@font-face {
  font-family: "BhostraLalaExtraBold";
  src: local("BhostraLalaExtraBold"),
    url(./assets/fonts/BhostraLalaExtraBold.otf) format("opentype");
}

@font-face {
  font-family: "BhostraLalaLight";
  src: local("BhostraLalaLight"),
    url(./assets/fonts/BhostraLalaLight.otf) format("opentype");
}

@font-face {
  font-family: "BhostraLalaRegular";
  src: local("BhostraLalaRegular"),
    url(./assets/fonts/BhostraLalaRegular.otf) format("opentype");
}

/* #tsparticles {
  background: transparent;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 90%,
    rgba(0, 0, 0, 1) 100%
  );
} */

#techtsparticles {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: 0; /* if you use -1 you have to set to `"window"` the interactivity.detectsOn property */
}
